html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @utilities responsive {
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  @font-face {
    font-family: 'Ultra';
    src: url('./fonts/Ultra-Regular.ttf');
    font-display: swap;
  }

  @font-face {
    font-family: 'Montserrat', sans-serif;
    src: url('./fonts/Montserrat-VariableFont_wght.ttf');
    font-display: swap;
  }
}

.ant-picker-ok button {
  background-color: #b373f2 !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  box-shadow: none;
  color: white;
}

.ais-SearchBox {
  margin-bottom: 2rem;
  position: relative;
  width: 100%;
}

.ais-InstantSearch {
  width: 100%;
  z-index: unset;
}

.ais-Hits-item {
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  cursor: pointer;
  align-items: start;
  width: 280px;

  &:hover {
    box-shadow: 0px 0px 30px 10px rgba(50, 46, 51, 0.2);
  }
}

.ais-Pagination-list {
  display: flex;
  justify-content: center;
  list-style: none;
}

.ais-Hits-list {
  display: grid;
  grid-template: 1fr / repeat(auto-fill, minmax(280px, 1fr));
  gap: 1rem;
  align-items: start;
  justify-items: center;
}

li.ais-Menu-item.ais-Menu-item--selected a.ais-Menu-link span.ais-Menu-label,
li.ais-Menu-item span.ais-Menu-count {
  background: #b373f2 !important;
  color: white !important;
}
